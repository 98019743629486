/*css all */

.gza-h-370 {
    height: 400px;
}

.gza-bg-white {
    background: #ffffff;
}

.gza-bg-gray {
    background: #e8e8e8;
}

.gza-bg-primary {
    background: #00b0ad;
}

.gza-c-gray {
    color: #999;
}

.gza-c-gray-2 {
    color: #777;
}

.gza-c-white {
    color: #fff;
}

.gza-f-12 {
    font-size: 12px;
}

.gza-f-14 {
    font-size: 14px;
}

.gza-f-16 {
    font-size: 16px;
}

.gza-f-22 {
    font-size: 22px;
}

.gza-b-radius-3px {
    border-radius: 3px;
}

.gza-pad-1-5 {
    padding: 1px 5px;
}

.mw-object-top {
    object-position: top;
}

.mw-object-fit {
    object-fit: cover;
}

.mw-object-contain {
    object-fit: contain;
}

.mw-center {
    margin: 0 auto !important;
    text-align: center !important;
}

.mw-left-auto {
    margin-left: auto !important;
}

.mw-right-auto {
    margin-right: auto !important;
}

.mw-flex {
    display: flex !important;
}

.mw-inline-flex {
    display: inline-flex !important;
}

.mw-flex-justify-left {
    justify-content: left !important;
}

.mw-flex-justify-center {
    justify-content: center;
}

.mw-flex-justify-c-end {
    justify-content: flex-end;
}

.mw-flex-justify-s-between {
    justify-content: space-between;
}

.mw-a-self-center {
    align-self: center !important;
}

.mw-a-items-center {
    align-items: center !important;
}

.mw-a-items-baseline {
    align-items: baseline !important
}

.mw-a-content-center {
    align-content: flex-end !important;
}

.mw-flex-wrap {
    flex-wrap: wrap !important;
}

.mw-flex-grow-1 {
    flex-grow: 1;
}

.mw-block {
    display: block !important;
}

.mw-wrapper {
    position: relative !important;
}

.mw-inherit {
    position: inherit;
}

.mw-bottom-absolute {
    bottom: 0!important;
    position: absolute !important;
}

.mw-top-absolute {
    top: 0!important;
    position: absolute !important;
}

.mw-right-absolute {
    right: 0!important;
    position: absolute !important;
}

.mw-right {
    right: 0 !important;
}

.mw-left-absolute {
    left: 0!important;
    position: absolute !important;
}

.mw-left {
    left: 0 !important;
}

.mw-top {
    top: 0 !important;
}

.mw-bottom {
    bottom: 0 !important;
}

.mw-fit-height {
    height: 100% !important;
}

.mw-fit-width {
    width: 100% !important;
}

.mw-auto-height {
    height: auto;
}

.mw-auto-width {
    width: auto;
}

.mw-pad-0 {
    padding: 0 !important;
}

.mw-pad-5rem {
    padding: .5rem;
}

.mw-pad-0 {
    padding: 0 !important;
}

.mw-pad-l-0 {
    padding-left: 0! important;
}

.mw-pad-t-0 {
    padding-top: 0 !important;
}

.mw-pad-r-0 {
    padding-right: 0!important;
}

.mw-pad-l-5 {
    padding-left: 5px!important
}

.mw-pad-r-5 {
    padding-right: 5px!important
}

.mw-pad-t-5 {
    padding-top: 5px!important
}

.mw-pad-b-5 {
    padding-bottom: 5px!important
}

.mw-pad-5 {
    padding: 5px!important
}

.mw-pad-l-10 {
    padding-left: 10px !important;
}

.mw-pad-r-10 {
    padding-right: 10px !important;
}

.mw-pad-t-10 {
    padding-top: 10px !important;
}

.mw-pad-b-10 {
    padding-bottom: 10px !important;
}

.mw-pad-10 {
    padding: 10px !important;
}

.mw-pad-l-15 {
    padding-left: 15px!important;
}

.mw-pad-r-15 {
    padding-right: 15px!important;
}

.mw-pad-t-15 {
    padding-top: 15px!important
}

.mw-pad-b-15 {
    padding-bottom: 15px!important
}

.mw-pad-15 {
    padding: 15px!important
}

.mw-pad-20 {
    padding: 20px!important
}

.mw-pad-l-20 {
    padding-left: 20px!important;
}

.mw-pad-r-20 {
    padding-right: 20px!important;
}

.mw-pad-t-20 {
    padding-top: 20px!important
}

.mw-pad-b-20 {
    padding-bottom: 20px!important
}

.mw-pad-25 {
    padding: 25px!important
}

.mw-pad-l-25 {
    padding-left: 25px!important;
}

.mw-pad-r-25 {
    padding-right: 25px!important;
}

.mw-pad-t-25 {
    padding-top: 25px!important
}

.mw-pad-b-25 {
    padding-bottom: 25px!important
}

.mw-pad-30 {
    padding: 30px!important
}

.mw-pad-l-30 {
    padding-left: 30px!important;
}

.mw-pad-r-30 {
    padding-right: 30px!important;
}

.mw-pad-t-30 {
    padding-top: 30px!important
}

.mw-pad-b-30 {
    padding-bottom: 30px!important
}

.mw-pad-40 {
    padding: 40px!important
}

.mw-pad-l-40 {
    padding-left: 40px!important;
}

.mw-pad-r-40 {
    padding-right: 40px!important;
}

.mw-pad-t-40 {
    padding-top: 40px!important
}

.mw-pad-b-40 {
    padding-bottom: 40px!important
}

.mw-pad-50 {
    padding: 50px!important
}

.mw-pad-l-50 {
    padding-left: 50px!important;
}

.mw-pad-r-50 {
    padding-right: 50px!important;
}

.mw-pad-t-50 {
    padding-top: 50px!important
}

.mw-pad-b-50 {
    padding-bottom: 50px!important
}

.mw-pad-30 {
    padding: 30px!important
}

.mw-m-0 {
    margin: 0px !important;
}

.mw-m-t-0 {
    margin-top: 0px !important;
}

.mw-m-b-0 {
    margin-bottom: 0px !important;
}

.mw-m-l-0 {
    margin-left: 0px !important;
}

.mw-m-r-0 {
    margin-right: 0px !important;
}

.mw-m-5 {
    margin: 5px !important;
}

.mw-m-t-5 {
    margin-top: 5px !important;
}

.mw-m-b-5 {
    margin-bottom: 5px !important;
}

.mw-m-l-5 {
    margin-left: 5px !important;
}

.mw-m-r-5 {
    margin-right: 5px !important;
}

.mw-m-10 {
    margin: 10px !important;
}

.mw-m-t-10 {
    margin-top: 10px !important;
}

.mw-m-b-10 {
    margin-bottom: 10px !important;
}

.mw-m-l-10 {
    margin-left: 10px !important;
}

.mw-m-r-10 {
    margin-right: 10px !important;
}

.mw-m-15 {
    margin: 15px !important;
}

.mw-m-t-15 {
    margin-top: 15px !important;
}

.mw-m-b-15 {
    margin-bottom: 15px !important;
}

.mw-m-l-15 {
    margin-left: 15px !important;
}

.mw-m-r-15 {
    margin-right: 15px !important;
}

.mw-m-20 {
    margin: 20px !important;
}

.mw-m-t-20 {
    margin-top: 20px !important;
}

.mw-m-b-20 {
    margin-bottom: 20px !important;
}

.mw-m-l-20 {
    margin-left: 20px !important;
}

.mw-m-r-20 {
    margin-right: 20px !important;
}

.mw-m-25 {
    margin: 25px !important;
}

.mw-m-t-25 {
    margin-top: 25px !important;
}

.mw-m-b-25 {
    margin-bottom: 25px !important;
}

.mw-m-l-25 {
    margin-left: 25px !important;
}

.mw-m-r-25 {
    margin-right: 25px !important;
}

.mw-m-30 {
    margin: 30px !important;
}

.mw-m-t-30 {
    margin-top: 30px !important;
}

.mw-m-b-30 {
    margin-bottom: 30px !important;
}

.mw-m-l-30 {
    margin-left: 30px !important;
}

.mw-m-r-30 {
    margin-right: 30px !important;
}

.mw-m-50 {
    margin: 50px !important;
}

.mw-m-t-50 {
    margin-top: 50px !important;
}

.mw-m-b-50 {
    margin-bottom: 50px !important;
}

.mw-m-l-50 {
    margin-left: 50px !important;
}

.mw-m-r-50 {
    margin-right: 50px !important;
}

.gza-logo {
    width: 40%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.gza-links-footer a, .gza-links-footer a:hover {
    text-decoration: none;
}

.gza-btn {
    margin-left: 10px;
    margin-right: 10px
}